<template>
    <div class="user-list">
        <!-- 筛选项 -->
        <el-form :inline="true" size="mini" :model="form" ref="filterFormRef" label-width="100px">
            <el-form-item label="菜单名称:" prop="menuName">
                <el-input style="width: 200px;" v-model="form.menuName" placeholder="请输入菜单名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="菜单状态:" prop="status">
                <el-select v-model="form.status" placeholder="请选择菜单状态" clearable>
                    <el-option label="有效" value="1"></el-option>
                    <el-option label="无效" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="margin-left: 20px;">
                <el-button type="primary" icon="el-icon-search" @click="searchList">查询</el-button>
                <el-button icon="el-icon-refresh-left" @click="resetFilterForm">重置</el-button>
            </el-form-item>
        </el-form>
        <!-- 筛选项 -->
        <div class="operate">
            <el-button type="primary" size="mini" @click="meuVisibleHnadle">配置菜单</el-button>
        </div>
        <!-- 表格内容 -->
        <el-table :data="tableData" border style="width: 100%" v-loading="loading">
            <el-table-column prop="id" label="菜单ID" width="80"></el-table-column>
            <el-table-column prop="menuName" label="菜单名称"></el-table-column>
            <el-table-column prop="systemName" label="系统名称"></el-table-column>
            <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                    <span>{{ scope.row.status == 0 ? "无效" : "有效" }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="createAt" label="创建时间"></el-table-column>
            <el-table-column prop="updateAt" label="更新时间"></el-table-column>
            <el-table-column fixed="right" label="操作" width="160">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="editHandle(scope.row)">
                        编辑
                    </el-button>
                    <el-button size="mini" @click="lookHandle(scope.row)">
                        查看
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 表格内容 -->
        <!-- 分页 -->
        <el-pagination
                small
                @current-change="handleCurrentChange"
                :page-size="limit"
                layout="total, prev, pager, next, jumper"
                :total="total"
                :current-page="currentPage">
        </el-pagination>
         <el-dialog
                :title="`${type==='add'?'新增':'编辑'}菜单配置`"
                :visible.sync="meuVisible"
                width="40%"
                @close="meuVisibleClose"
        >
            <el-form
                    class="form"
                    size="mini"
                    :model="menuForm"
                    ref="refMenu"
                    :rules="rules"
                    label-width="100px">
                <el-form-item label="系统名称:"   prop="systemName">
                    <el-select v-model="menuForm.systemName" :disabled="type==='edit'" filterable placeholder="请选择">
                        <el-option v-for="item in systemList" :key="item.dictValue" :label="item.dictName" :value="item.dictValue"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="菜单名称:"   prop="menuName">
                    <el-input v-model="menuForm.menuName"></el-input>
                </el-form-item>
                <el-form-item label="调取路径:"   prop="sysUrl">
                    <el-input v-model="menuForm.sysUrl"></el-input>
                </el-form-item>
                <el-form-item label="状态:"   prop="status">
                    <el-select v-model="menuForm.status" :disabled="type==='edit'" clearable>
                        <el-option label="有效" value="1"></el-option>
                        <el-option label="无效" value="0"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button size="mini" @click="dislogCancelHnadle">取 消</el-button>
              <el-button size="mini" type="primary" @click="submit">提 交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { 
    getQuerySysMenuUrl,
    addSysMenuUrlApi,
    editSysMenuUrlApi
} from "@/api/menu.js";
import { getQueryDictValueByType } from '@/api/systemManagement.js'
export default {
    // name: "menu",
    // components: { configurationMenu },
    props: {},
    data() {
        return {
            type:'',
            menuForm:{
                systemName:'',
                menuName:'',
                sysUrl:'',
                status:'0',
            },
            meuVisible:false,
            tableData:[],
            loading:false,
            form: {
                menuName: "",
                status: ""
            },
            total: 0,
            currentPage: 1,
            limit: 10,
            systemList:[],
            rules:{
                systemName: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                menuName: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                sysUrl: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                status: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ]

            }
        };
    },
  async  created() {
    
    await this.getList()
    await this.getQueryDictValueByTypeList()
    },
    methods: {
     async   getQueryDictValueByTypeList(){
            try {
              let res= await  getQueryDictValueByType({dictType:'SYSTEM_CODE'})
              if(res.code===0){
                this.systemList=res.data
              }
            } catch (error) {
                
            }
        },
      async  getList(){
            try {
                this.loading=true
                let params={
                    pageNo:this.currentPage,
                    pageSize:this.limit,
                    ...this.form
                }
                this.roleTableLoading=true
                this.tableData=[]
                let res =await getQuerySysMenuUrl(params)
                this.tableData=res.data.records
                this.total=res.data.total
                this.currentPage=res.data.current
            } catch (error) {
                
            } finally{
                this.loading=false
            }
        },
        submit(){
            this.$refs.refMenu.validate(async (valid) => {
                if(valid){
                    if(this.type==='add'){
                        await this.addSysMenuUrlApiList()
                    }else if(this.type==='edit'){
                        await this.editSysMenuUrlApiList()
                    }
                }else{
                    return false;
                }
            })
        },
      async  addSysMenuUrlApiList(){ // 新增
            try {
                let params={
                    // menuId:5,
                    menuCode:'add_menu_url',
                    systemName:this.menuForm.systemName,
                    menuName:this.menuForm.menuName,
                    sysUrl:this.menuForm.sysUrl,
                    status:Number(this.menuForm.status),
                }
                params.status=Number(this.menuForm.status)
                let res=await addSysMenuUrlApi(params)
                if(res.code===0){
                    this.$message({
                        type: 'success',
                        message: '新增成功!'
                    });
                    this.meuVisible=false
                    await this.getList()
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });  
                }
            } catch (error) {
                this.$message({
                        type: 'error',
                        message: `${error||'操作失败!'}`
                    });  
            }
        },
        async  editSysMenuUrlApiList(){ // 编辑
            try {
                let params={
                    // menuId:6,
                    menuCode:'edit_menu_url',
                    systemName:this.menuForm.systemName,
                    menuName:this.menuForm.menuName,
                    sysUrl:this.menuForm.sysUrl,
                    status:Number(this.menuForm.status),
                    id:this.menuForm.id
                }
                let res=await editSysMenuUrlApi(params)
                if(res.code===0){
                    this.$message({
                        type: 'success',
                        message: '编辑成功!'
                    });
                    this.meuVisible=false
                    await this.getList()
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });  
                }
            } catch (error) {
                this.$message({
                        type: 'error',
                        message: `${error||'操作失败!'}`
                    });  
            }
        },
        meuVisibleClose(m){
            this.meuVisible=false
            this.clearForm()
            this.$refs['refMenu'].resetFields();
        },
        clearForm(){
            Object.keys(this.menuForm).forEach(item=>{
                this.menuForm[item]=''
            })
        },
        dislogCancelHnadle(){ // 弹框的取消
            this.meuVisible = false,
            this.clearForm()
            this.$refs['refMenu'].resetFields();
        },
        // 配置菜单
        meuVisibleHnadle(){
            this.type='add'
            this.meuVisible=true
        },
        // 编辑用户
        async editHandle(row) {
            this.type='edit'
            row.status=row.status+''
            this.meuVisible=true
            this.menuForm={
                ...row
            }
        },
        lookHandle(row){ 
            this.$router.push({ 
                path: `/menu/detail/${row.id}` ,
                query: {
                    ...row
                }
             });//detail
        },
         // 搜索重置
      async   resetFilterForm() {
            this.$refs.filterFormRef.resetFields();
            await this.getList()
        },
     async   handleCurrentChange(val) {
            this.currentPage = val
            await this.getList()
        },
      async  searchList(){
            this.currentPage=1
            await this.getList()
        }
    },
};
</script>

<style scoped lang="scss">
.user-list {
    //overflow-y: scroll;
    .form {
        display: block;
    }

    .operate {
        margin: 25px 0;
        float: right;
    }

    .email_verify_btn {
        background: $color-theme-normal !important;
        color: #ffffff !important;
        border: 2px solid $color-theme-normal !important;
        border-radius: 0 !important;
        font-size: $font-small !important;
    }
    .dialog-footer{
        display: flex;
        justify-content: center;
    }
}
</style>